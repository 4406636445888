import { LOGIN, LOGOUT, ERROR, LOGIN_FAILED } from './authTypes';

export const logIn = (body) => dispatch =>{
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},

        body: JSON.stringify(body)
    }

    fetch(`${process.env.REACT_APP_SECRET_API}/auth/login/`, requestOptions)
        .then(resp => resp.json())
        .then(resp => {
            if(resp.password || resp.username || resp.detail || resp.email){
            dispatch({
                type: ERROR,
                payload: resp
            });
            } else {
            let campus = parseJwt(resp.access).campus;
            let manager = parseJwt(resp.access).manager;
            let accountant = parseJwt(resp.access).accountant;
            document.cookie = `refresh=${resp.refresh};max-age=86400;domain=${process.env.REACT_APP_DOMAIN}`
            document.cookie = `access=${resp.access};max-age=57600;domain=${process.env.REACT_APP_DOMAIN}`
            if (campus) {
                document.cookie = `campus=${campus};max-age=57600;domain=${process.env.REACT_APP_DOMAIN}`;
              }
            if (manager) {
            document.cookie = `manager=${manager};max-age=57600;domain=${process.env.REACT_APP_DOMAIN}`;
            }
            if (accountant) {
                document.cookie = `accountant=${accountant};max-age=57600;domain=${process.env.REACT_APP_DOMAIN}`;
                }
            dispatch({
                type: LOGIN,
                payload: resp.access,
                campus: campus,
                manager: manager,
                accountant: accountant,
            });
        }})
};

export const logOut = () => dispatch => {

    document.cookie = `access='';expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=${process.env.REACT_APP_DOMAIN}`
    document.cookie = `refresh='';expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=${process.env.REACT_APP_DOMAIN}`
    document.cookie = `campus='';expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=${process.env.REACT_APP_DOMAIN}`
    document.cookie = `manager='';expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=${process.env.REACT_APP_DOMAIN}`
    document.cookie = `accountant='';expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=${process.env.REACT_APP_DOMAIN}`
    
    dispatch({
        type: LOGOUT,
    });
}

export const setLoginFailed = () => dispatch => {
    
    dispatch({
        type: LOGIN_FAILED,
    });
}

const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };