//React
import * as React from 'react';
import {useState} from 'react';
//Material UI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
//Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setNewSubMinistryModal, makePostRequest, makeGetRequest } from "../../state/actions"
//Components
//Routing
import { useParams } from "react-router-dom";

function NewMinistryModal(props) {

    let { pk } = useParams();
    const [name, setName] = useState('');
    const [budget, setBudget] = useState(0);

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleBudgetChange = (event) => {
        setBudget(event.target.value);
    };

    const handleSubmit = () => {
        const body = { 
            'name': name,
            'budget': budget,
        };
        props.makePostRequest(body, `claim_submittal/ministries/subdivisions/${pk}/`, false)
        setTimeout(() => props.makeGetRequest(`claim_submittal/ministries/subdivisions/${pk}/`, 'MinistrySubDivisionListData'), 100);
        props.setNewSubMinistryModal(false)
    };

    return (
        <div name="NewMinistryModal">
        
        <Dialog 
        open={props.new_subministry_modal_open} 
        onClose={()=>props.setNewSubMinistryModal(false)}
        >
            <DialogTitle >
                <Button
                fullWidth 
                size="large" 
                variant="contained">
                    <Typography variant="h5" align="center">
                        NUWE AKSIE/KOMMISSIE ONDERAFDELING
                    </Typography>
                </Button>
            </DialogTitle>
            <DialogContent>

                <Typography sx={{mt: 4}} variant="h6">
                    Naam/Beskrywing
                </Typography>
                <TextField 
                value={name}
                onChange={handleNameChange}
                required
                fullWidth
                />
                <Typography sx={{mt: 4}} variant="h6">
                    Begroting
                </Typography>
                <TextField onChange={handleBudgetChange}
                required
                fullWidth
                error= {!(/^(([0-9]{1,10})(\.[0-9]{1,2})?)$/.test(budget))}
                helperText={!(/^(([0-9]{1,10})(\.[0-9]{1,2})?)$/.test(budget)) 
                ? "Die bedrag mag slegs 10 numeriese karakters of minder wees, 2 desimale plekke is ook aanvaarbaar." 
                : "" }
                inputProps={{ inputMode: 'numeric', type: "number" }}
                value={budget}          
                />

            </DialogContent>
            <DialogActions>

                <Button
                variant="contained" 
                color="secondary" 
                onClick={()=>props.setNewSubMinistryModal(false)}>
                    Kanselleer
                </Button>

                <Button
                variant="contained" 
                color="success" 
                onClick={handleSubmit}
                >
                    Bevestig
                </Button>

            </DialogActions>
        </Dialog>
        </div>
    );
}

NewMinistryModal.propTypes = {
    setNewSubMinistryModal: PropTypes.func.isRequired,
    makePostRequest: PropTypes.func.isRequired,
    makeGetRequest: PropTypes.func.isRequired,
    new_subministry_modal_open: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return { 
        new_subministry_modal_open: state.modal.new_subministry_modal_open,
    };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNewSubMinistryModal: (open) => 
        dispatch(setNewSubMinistryModal(open)),
    makePostRequest: (body, url, file) => 
        dispatch(makePostRequest(body, url, file)),
    makeGetRequest: (url, componentName) => 
        dispatch(makeGetRequest(url, componentName)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewMinistryModal);