import { 
    NEW_MINISTRY,
    NEW_SUBMINISTRY,
    CHANGE_MINISTRY_NAME,
    CHANGE_SUBMIN_NAME,
    CHANGE_SUBMIN_BUDGET,
    CHANGE_SUBMIN_SPENDING,  
} from '../actions/modalTypes';

const initialState = {
    submin_index: "",
    new_ministry_modal_open: false,
    new_subministry_modal_open: false,
    change_ministry_name_modal_open: false,
    change_submin_name_modal_open: false,
    change_submin_budget_modal_open: false,
    change_submin_spending_modal_open: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case NEW_MINISTRY:
            return {  
                ...state,
                new_ministry_modal_open: action.open
            };
        case NEW_SUBMINISTRY:
            return {  
                ...state,
                new_subministry_modal_open: action.open
            };
        case CHANGE_MINISTRY_NAME:
            return {  
                ...state,
                change_ministry_name_modal_open: action.open
            };
        case CHANGE_SUBMIN_NAME:
            return {  
                ...state,
                change_submin_name_modal_open: action.open,
                submin_index: action.submin_index
            };
        case CHANGE_SUBMIN_BUDGET:
            return {  
                ...state,
                change_submin_budget_modal_open: action.open,
                submin_index: action.submin_index
            };
        case CHANGE_SUBMIN_SPENDING:
            return {  
                ...state,
                change_submin_spending_modal_open: action.open,
                submin_index: action.submin_index
            };
        default:
            return state;
    };
};

export default reducer;