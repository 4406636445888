import { LOGIN, LOGOUT, ERROR, LOGIN_FAILED } from '../actions/authTypes';

const initialState = getCookie("access") 
?   {
    token: `Bearer ${getCookie("access")}`, 
    loggedIn: true,
    campus: getCookie("campus"),
    manager: getCookie("manager"),
    accountant: getCookie("accountant"),
    login_failed: false, 
    }
:   { 
    token:'', 
    loggedIn:false,
    campus: '',
    manager: false,
    accountant: false,
    login_failed: false,
    }

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:
            return {  
                ...state,
                token: 'Bearer ' + action.payload,
                campus: action.campus,
                manager: action.manager,
                accountant: action.accountant,
                loggedIn: true
            };
        case LOGOUT:
            return {
                ...state,
                loggedIn: false,
                token: '',
                campus: '',
                manager: false,
                accountant: false,
            };
        case ERROR:
            return {
                ...state,
                loggedIn: false,
                login_failed: true,
            };
        case LOGIN_FAILED:
            return {
                ...state,
                login_failed: false,
            };            
        default:
            return state;
    };
};

export default reducer;

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }