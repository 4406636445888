//React
import * as React from 'react';
//Material UI
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Button from '@mui/material/Button';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import { Typography } from '@mui/material';
//Routing
import { Link } from 'react-router-dom';
//Redux
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logOut} from "../state/actions/"

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

function Menu(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  if(props.loggedIn){
    return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>

                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  Tjekaanvraag: {props.campus}
                </Typography>

                <Button 
                  variant="contained"
                  color="secondary"
                  onClick={()=>props.logOut()}
                  startIcon={<ExitToAppIcon />}>
                  Teken Uit
                </Button>

          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          
          <List>
            <Link style={{textDecorationLine: 'none'}} to={`/`}>
              <ListItem onClick={handleDrawerClose} button key='Tjekaanvrae Lys'>
                <ListItemIcon>
                  <ListAltIcon />
                </ListItemIcon>
                <ListItemText primary='Tjekaanvrae Lys' />
              </ListItem>
            </Link>
            <Link style={{textDecorationLine: 'none'}} to={`/create_claim`}>
              <ListItem onClick={handleDrawerClose} button key='Nuwe Tjekaanvraag'>
                <ListItemIcon>
                  <PostAddIcon />
                </ListItemIcon>
                <ListItemText primary='Nuwe Tjekaanvraag' />
              </ListItem>
            </Link>
            {props.manager &&(
              <Link style={{textDecorationLine: 'none'}} to={`/ministries`}>
              <ListItem onClick={handleDrawerClose} button key='Aksies/Kommissies'>
                <ListItemIcon>
                  <AccessibilityNewIcon />
                </ListItemIcon>
                <ListItemText primary='Aksies/Kommissies' />
              </ListItem>
            </Link>
            )}           

          </List>
  
        </Drawer>
        <Main open={open}>
          <DrawerHeader />
        </Main>
      </Box>
    );
  } else {
    return <></>;
  }
  
}

Menu.propTypes = {

  logOut: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  campus: PropTypes.string.isRequired,
  manager: PropTypes.bool.isRequired,
};

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => dispatch(logOut()),
  }}

const mapStateToProps = (state, ownProps) => {
    return{ 
      loggedIn: state.auth.loggedIn,
      campus: state.auth.campus,
      manager: state.auth.manager,
    }
  };

export default connect(mapStateToProps, mapDispatchToProps)(Menu);