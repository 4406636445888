import {  
    CLAIMFORMPK,
} from '../actions/varsTypes';

const initialState = {
    claim_form_pk: '',
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CLAIMFORMPK:
            return {  
                ...state,
                claim_form_pk: action.payload
            };
        default:
            return state;
    };
};

export default reducer;