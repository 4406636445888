import React from "react";
import Table from "./Table";

function TableMinistriesList(props) {
  const columns = React.useMemo(
    () => [
      {
        Header: "AKSIE / KOMMISSIE",
        accessor: "name",
        width: 2000,
        minWidth: 50,
      },
    ],
    []
  );

  const data = React.useMemo(() => props.ministry_list_data, [props]);

  return (
    <>
      <Table columns={columns} data={data} />
    </>
  );
}

export default TableMinistriesList;
