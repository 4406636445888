//React
import * as React from 'react';
import {useState} from 'react';
//Material UI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
//Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setNewMinistryModal, makePostRequest, makeGetRequest } from "../../state/actions/"
//Components

function NewMinistryModal(props) {

    const [name, setName] = useState(null);

    const handleChange = (event) => {
        setName(event.target.value);
    };

    const handleSubmit = () => {
        const body = { 
            'name': name,
        };
        props.makePostRequest(body, 'claim_submittal/ministries/', false)
        setTimeout(() => props.makeGetRequest('claim_submittal/ministries/', 'MinistryListData'), 100);
        props.setNewMinistryModal(false)
    };

    return (
        <div name="NewMinistryModal">
        
        <Dialog 
        open={props.new_ministry_modal_open} 
        onClose={()=>props.setNewMinistryModal(false)}
        >
            <DialogTitle >
                <Button
                fullWidth 
                size="large" 
                variant="contained">
                    <Typography variant="h5" align="center">
                        NUWE AKSIE/KOMMISSIE
                    </Typography>
                </Button>
            </DialogTitle>
            <DialogContent>

                <Typography sx={{mt: 4}} variant="h6">
                    Naam/Beskrywing
                </Typography>
                <TextField 
                value={name}
                onChange={handleChange}
                required
                fullWidth
                />

            </DialogContent>
            <DialogActions>

                <Button
                variant="contained" 
                color="secondary" 
                onClick={()=>props.setNewMinistryModal(false)}>
                    Kanselleer
                </Button>

                <Button
                variant="contained" 
                color="success" 
                onClick={handleSubmit}
                >
                    Bevestig
                </Button>

            </DialogActions>
        </Dialog>
        </div>
    );
}

NewMinistryModal.propTypes = {
    setNewMinistryModal: PropTypes.func.isRequired,
    makePostRequest: PropTypes.func.isRequired,
    makeGetRequest: PropTypes.func.isRequired,
    new_ministry_modal_open: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return { 
        new_ministry_modal_open: state.modal.new_ministry_modal_open,
    };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNewMinistryModal: (open) => 
        dispatch(setNewMinistryModal(open)),
    makePostRequest: (body, url, file) => 
        dispatch(makePostRequest(body, url, file)),
    makeGetRequest: (url, componentName) => 
        dispatch(makeGetRequest(url, componentName)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewMinistryModal);