//React
import * as React from 'react';
import {useState} from 'react';
//Material UI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
//Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setChangeSubminSpendingModal, makePatchRequest, makeGetRequest } from "../../state/actions"
//Components
//Router
import { useParams } from "react-router-dom";

function ChangeSubminSpendingModal(props) {

    const [spending, setSpending] = useState(null);
    let { pk } = useParams();

    const handleChange = (event) => {
        setSpending(event.target.value);
    };

    const handleSubmit = () => {
        let submin_pk = props.ministry_subdivision_list_data.ministry_subdivisions[props.submin_index].id
        const body = { 
            'update_type': "total_spending",
            'total_spending': spending,            
        };
        props.makePatchRequest(body, submin_pk, 'claim_submittal/ministries/subdivisions')
        setSpending('')
        setTimeout(() => props.makeGetRequest(
            `claim_submittal/ministries/subdivisions/${pk}/`,
            'MinistrySubDivisionListData'), 100);
        props.setChangeSubminSpendingModal(false)
    };

    return (
        <div name="ChangeSubminSpendingModal">
        <Dialog 
        open={props.change_submin_spending_modal_open} 
        onClose={()=>props.setChangeSubminSpendingModal(false)}
        >
            <DialogTitle >
                <Button
                fullWidth 
                size="large" 
                variant="contained">
                    <Typography variant="h5" align="center">
                        ONDERAFDELING TOTAAL SPANDEER VERANDERING
                    </Typography>
                </Button>
            </DialogTitle>
            <DialogContent>

                <Typography sx={{mt: 4}} variant="h6">
                    Nuwe Totaal Spandeer Bedrag
                </Typography>
                <TextField 
                onChange={handleChange}
                required
                fullWidth
                error= {!(/^(([0-9]{1,10})(\.[0-9]{1,2})?)$/.test(spending))}
                helperText={!(/^(([0-9]{1,10})(\.[0-9]{1,2})?)$/.test(spending)) 
                ? "Die bedrag mag slegs 10 numeriese karakters of minder wees, 2 desimale plekke is ook aanvaarbaar." 
                : "" }
                inputProps={{ inputMode: 'numeric', type: "number" }}
                value={spending}         
                />

            </DialogContent>
            <DialogActions>

                <Button
                variant="contained" 
                color="secondary" 
                onClick={()=>props.setChangeSubminSpendingModal(false)}>
                    Kanselleer
                </Button>

                <Button
                variant="contained" 
                color="success" 
                onClick={handleSubmit}
                >
                    Bevestig
                </Button>

            </DialogActions>
        </Dialog>
        </div>
    );
}

ChangeSubminSpendingModal.propTypes = {
    setChangeSubminSpendingModal: PropTypes.func.isRequired,
    makePatchRequest: PropTypes.func.isRequired,
    makeGetRequest: PropTypes.func.isRequired,
    change_submin_spending_modal_open: PropTypes.bool.isRequired,
    ministry_subdivision_list_data: PropTypes.object,
    submin_index: PropTypes.string
};

const mapStateToProps = (state, ownProps) => {
  return { 
        change_submin_spending_modal_open: state.modal.change_submin_spending_modal_open,
        ministry_subdivision_list_data: state.handleAPI.ministry_subdivision_list_data,
        submin_index: state.modal.submin_index,
    };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setChangeSubminSpendingModal: (open) => 
        dispatch(setChangeSubminSpendingModal(open)),
    makePatchRequest: (body, pk, url) => 
        dispatch(makePatchRequest(body, pk, url)),
    makeGetRequest: (url, componentName) => 
        dispatch(makeGetRequest(url, componentName)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeSubminSpendingModal);