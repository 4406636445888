//React
import * as React from 'react';
//Material UI
import { Grid } from "@mui/material";
//Components
import website_maintenance from '../static/website_maintenance.jpg'

function Maintenance() {

  return (
    <Grid container alignItems="center" sx={{mt:4}}>
      <Grid item xs={1} md={3}/>
      <Grid item xs={8} md={8}>
        <img src={website_maintenance} width="600" height="600"/>
      </Grid>
      <Grid item xs={3} md={1}/>
    </Grid>
  )
}

export default Maintenance;