//React
import * as React from "react";
import {useState} from 'react';
//Redux
import { setAPISuccess, setAPIError } from "../state/actions/";
import PropTypes from "prop-types";
import { connect } from "react-redux";
//Material UI
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Notifications(props) {

  // const [file_error, setFileError] = useState(false);

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    props.setAPISuccess();
  };

  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    props.setAPIError();
  };

  // const handleFileErrorClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setFileError(false);
  // };

  return (
    <>
      <Snackbar
        open={props.api_success}
        autoHideDuration={6000}
        onClose={handleSuccessClose}
      >
        <Alert
          onClose={handleSuccessClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {props.api_success_message}
        </Alert>
      </Snackbar>

      <Snackbar
        open={props.api_error}
        autoHideDuration={6000}
        onClose={handleErrorClose}
      >
        <Alert
          onClose={handleErrorClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {props.api_error_message}
        </Alert>
      </Snackbar>

      {/* <Snackbar
        open={file_error}
        autoHideDuration={6000}
        onClose={handleFileErrorClose}
      >
        <Alert
          onClose={handleFileErrorClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Die dokument wat u wil oplaai is te groot! Dit moet kleiner as 20MB
          wees.
        </Alert>
      </Snackbar> */}

    </>
  );
}

Notifications.propTypes = {
  api_error: PropTypes.bool.isRequired,
  api_success: PropTypes.bool.isRequired,
  api_error_message: PropTypes.string.isRequired,
  api_success_message: PropTypes.string.isRequired,
  setAPISuccess: PropTypes.func.isRequired,
  setAPIError: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    api_error: state.handleAPI.api_error,
    api_success: state.handleAPI.api_success,
    api_error_message: state.handleAPI.api_error_message,
    api_success_message: state.handleAPI.api_success_message,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAPISuccess: () => dispatch(setAPISuccess()),
    setAPIError: () => dispatch(setAPIError()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
