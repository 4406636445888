//React
import * as React from 'react';
import {useState} from 'react';
//Material UI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
//Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setChangeMinistryNameModal, makePatchRequest, makeGetRequest } from "../../state/actions"
//Components
//Router
import { useParams } from "react-router-dom";

function ChangeMinistryNameModal(props) {

    const [name, setName] = useState('');
    let { pk } = useParams();

    const handleChange = (event) => {
        setName(event.target.value);
    };

    const handleSubmit = () => {
        const body = { 
            'name': name,
        };
        props.makePatchRequest(body, pk, 'claim_submittal/ministries')
        setTimeout(() => props.makeGetRequest(
            `claim_submittal/ministries/subdivisions/${pk}/`,
            'MinistrySubDivisionListData'), 100);
        props.setChangeMinistryNameModal(false)
        setName('')
    };

    return (
        <div name="ChangeMinistryNameModal">
        
        <Dialog 
        open={props.change_ministry_name_modal_open} 
        onClose={()=>props.setChangeMinistryNameModal(false)}
        >
            <DialogTitle >
                <Button
                fullWidth 
                size="large" 
                variant="contained">
                    <Typography variant="h5" align="center">
                        AKSIE/KOMMISSIE NAAM VERANDERING
                    </Typography>
                </Button>
            </DialogTitle>
            <DialogContent>

                <Typography sx={{mt: 4}} variant="h6">
                    Nuwe Naam/Beskrywing
                </Typography>
                <TextField 
                value={name}
                onChange={handleChange}
                required
                fullWidth
                />

            </DialogContent>
            <DialogActions>

                <Button
                variant="contained" 
                color="secondary" 
                onClick={()=>props.setChangeMinistryNameModal(false)}>
                    Kanselleer
                </Button>

                <Button
                variant="contained" 
                color="success" 
                onClick={handleSubmit}
                >
                    Bevestig
                </Button>

            </DialogActions>
        </Dialog>
        </div>
    );
}

ChangeMinistryNameModal.propTypes = {
    setChangeMinistryNameModal: PropTypes.func.isRequired,
    makePatchRequest: PropTypes.func.isRequired,
    makeGetRequest: PropTypes.func.isRequired,
    change_ministry_name_modal_open: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return { 
        change_ministry_name_modal_open: state.modal.change_ministry_name_modal_open,
    };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setChangeMinistryNameModal: (open) => 
        dispatch(setChangeMinistryNameModal(open)),
    makePatchRequest: (body, pk, url) => 
        dispatch(makePatchRequest(body, pk, url)),
    makeGetRequest: (url, componentName) => 
        dispatch(makeGetRequest(url, componentName)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeMinistryNameModal);