//Material UI
import Button from "@mui/material/Button";
//Redux
import { 
  setChangeSubminNameModal, 
  setChangeSubminBudgetModal,
  setChangeSubminSpendingModal
  } from "../../state/actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const ButtonTableModal = (props) => {

  const setModal = () => {  

    switch (props.header) {  
      case "AKSIE/KOMMISSIE ONDERAFDELING":
        props.setChangeSubminNameModal(true, props.index)
        break
      case "BEGROTING (RAND)":
        props.setChangeSubminBudgetModal(true, props.index)
        break
      case "TOTAAL SPANDEER (RAND)":
        props.setChangeSubminSpendingModal(true, props.index)
        break
      default:
        break
    }
  };

  return (
    <Button 
      onClick={setModal} 
      variant="outlined"
      color="primary"
      size="small">
        {props.display}
    </Button>
  );
};

ButtonTableModal.propTypes = {
  setChangeSubminNameModal: PropTypes.func.isRequired,
  setChangeSubminBudgetModal: PropTypes.func.isRequired,
  setChangeSubminSpendingModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => {
  return {
    setChangeSubminNameModal: (open, submin_index) =>
      dispatch(setChangeSubminNameModal(open, submin_index)), 
    setChangeSubminBudgetModal: (open, submin_index) =>
      dispatch(setChangeSubminBudgetModal(open, submin_index)), 
    setChangeSubminSpendingModal: (open, submin_index) =>
      dispatch(setChangeSubminSpendingModal(open, submin_index)), 
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ButtonTableModal);
