import { 
    GET_REQUEST, 
    PATCH_REQUEST, 
    API_ERROR, 
    SET_API_ERROR,
    API_SUCCESS,
    SET_API_SUCCESS,  
    POST_REQUEST, 
    LOADING 
    } from '../actions/handleAPITypes';

const initialState = {

    home_submitted_data: [],
    home_approved_data: [], 
    home_paid_data: [],
    home_rejected_data: [],
    ministry_list_data: [],
    ministry_subdivision_list_data: {},
    view_claim_data: {},
    api_error: false,
    api_error_message: 'Generic error message',
    api_success: false,
    api_success_message: 'Generic success message',
    loading: false,   
    
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_REQUEST:
            switch (action.componentName){
                case 'HomeSubmitted':
                    return{
                        ...state,
                        home_submitted_data: action.data,
                    };
                case 'HomeApproved':
                    return{
                        ...state,
                        home_approved_data: action.data,
                    };
                case 'HomePaid':
                    return{
                        ...state,
                        home_paid_data: action.data,
                    };
                case 'HomeRejected':
                    return{
                        ...state,
                        home_rejected_data: action.data,
                    };
                case 'ViewClaim':
                    return{
                        ...state,
                        view_claim_data: action.data,
                    };
                case 'MinistryListData':
                    return{
                        ...state,
                        ministry_list_data: action.data,
                    };
                case 'MinistrySubDivisionListData':
                    return{
                        ...state,
                        ministry_subdivision_list_data: action.data,
                    };
                default:
                    return state;
            }
        case PATCH_REQUEST:            
            return {  
                ...state,
                loading: false
            }; 
        case POST_REQUEST:            
            return {  
                ...state,
                loading: false
            };
        case API_ERROR:         
            return {  
                ...state,
                api_error: true,
                api_error_message: action.api_error_message,
                loading: false
            }; 
        case SET_API_ERROR:            
            return {  
                ...state,
                api_error: false
            };
        case API_SUCCESS:       
            return {  
                ...state,
                api_success: true,
                api_success_message: action.api_success_message,
                loading: false
            }; 
        case SET_API_SUCCESS:            
            return {  
                ...state,
                api_success: false
            };      
        case LOADING:            
            return {  
                ...state,
                loading: true
            }; 
        default:
            return state;
    };
};

export default reducer;