import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import MainRouter from './Components/Routing/MainRouter';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import { store } from "./state/store";
import Menu from './Components/Menu';
import theme from "./theme"
import {  ThemeProvider } from "@mui/material/styles";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Menu/>
          <MainRouter/>
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
