import { Redirect, Route } from 'react-router-dom';
import React from 'react'
import { useSelector } from 'react-redux';

const ManagerRoute = ({ component: Component, ...rest }) => {

    const loggedIn = useSelector((state) => state.auth.loggedIn)
    const manager = useSelector((state) => state.auth.manager)
  
    return (
      <>
      <Route
        {...rest}
        render={props =>
          (loggedIn && manager) ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          )
        }
      />
      </>
    )
  }
  
  export default ManagerRoute
