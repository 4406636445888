import { 
    PATCH_REQUEST, 
    POST_REQUEST, 
    GET_REQUEST, 
    API_ERROR, 
    SET_API_ERROR,
    API_SUCCESS, 
    SET_API_SUCCESS, 
    LOADING 
} from './handleAPITypes';

const comms_error_message = 'GET Communication Error. Please contact your administrator.'

export const makeGetRequest = (url, componentName) => {   

    return (dispatch) => {

        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json',
                        'Authorization': `Bearer ${getCookie("access")}`},
            };
    
        return fetch(`${process.env.REACT_APP_SECRET_API}/${url}`, requestOptions)
        .then(res => res.json())
        .then((data) => {
            if(data.error){
                dispatch({
                    type: API_ERROR,
                    api_error_message: data.error
                });
            } else if(data.success){
                dispatch({
                    type: API_SUCCESS,
                    api_success_message: data.success
                });
                dispatch({
                    type: GET_REQUEST,
                    data: data,
                    componentName: componentName,
                })
            } else {
                dispatch({
                    type: GET_REQUEST,
                    data: data,
                    componentName: componentName,
                })
            }           

        })
        .catch((e) => {
            console.log('Catch', e);
            dispatch({
                type: API_ERROR,
                api_error_message: comms_error_message
            });
        })

    }
}

export const makePatchRequest = (body, pk, url) => {

    return (dispatch) => {

        const requestOptions = {
            method: 'PATCH',
            headers: {'Content-Type': 'application/json',
                      'Authorization': `Bearer ${getCookie("access")}`},      
            body: JSON.stringify(body)

            };
    
        return fetch(`${process.env.REACT_APP_SECRET_API}/${url}/${pk}/`, requestOptions)
        .then(res => res.json())
        .then((data) => {
            if(data.error){
                dispatch({
                    type: API_ERROR,
                    api_error_message: data.error
                });
            } else if(data.success){
                dispatch({
                    type: API_SUCCESS,
                    api_success_message: data.success
                });
            }
        })
        .catch((e) => {
            console.log('Catch', e);
            dispatch({
                type: API_ERROR,
                api_error_message: comms_error_message
            });
        })
    }
}

export const makePostRequest = (body, url, file) => {

    return (dispatch) => {

        let headers = {}
        
        if (!file){
            headers = {'Content-Type': 'application/json', 'Authorization': `Bearer ${getCookie("access")}`}
            body = JSON.stringify(body)
        } else {
            headers = {'Authorization': `Bearer ${getCookie("access")}`}
        }

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: body
            };
    
        return fetch(`${process.env.REACT_APP_SECRET_API}/${url}`, requestOptions)
        .then(res => res.json())
        .then((data) => {
            if(data.error){
                dispatch({
                    type: API_ERROR,
                    api_error_message: data.error
                });
            } else if(data.success){
                dispatch({
                    type: API_SUCCESS,
                    api_success_message: data.success
                });
            }
        })
        .catch((e) => {
            console.log('Catch', e);
            dispatch({
                type: API_ERROR,
                api_error_message: comms_error_message
            });
        })
    }
}

export const setAPISuccess = () => {
    return (dispatch) => {
        dispatch({type: SET_API_SUCCESS})
    }
}

export const setAPIError = () => {
    return (dispatch) => {
        dispatch({type: SET_API_ERROR})
    }
}

export const setLoading = () => {
    return (dispatch) => {
        dispatch({type: LOADING})
    }
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }