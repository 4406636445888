import * as React from 'react';
import { useParams } from "react-router-dom";
import {useEffect} from 'react'
import { useState} from 'react';
// Redux imports
import { makeGetRequest, makePostRequest, 
          makePatchRequest, setAPISuccess, setAPIError} from "../state/actions/"
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Material UI components
import Button from '@mui/material/Button';
import { Container, Grid, Typography } from '@mui/material';
import { useHistory } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ViewClaim(props) {

  let { pk } = useParams();
  let history = useHistory();

  const [approval_value, setApproval] = React.useState(false);
  const [paid_value, setPayment] = React.useState(false);
  const [rejected_value, setRejected] = React.useState(false);
  const [approval_disabled, setApprovalDisabled] = React.useState(true);
  const [payment_disabled, setPaymentDisabled] = React.useState(true);
  const [rejected_disabled, setRejectedDisabled] = React.useState(true);

  const [success_open, setSuccessOpen] = React.useState(false);
  const [error_open, setErrorOpen] = React.useState(false);

  const [paid_open, setPaidOpen] = React.useState(false);
  const [rejected_open, setRejectedOpen] = React.useState(false);
  const [approved_open, setApprovedOpen] = React.useState(false);

  useEffect(() => {
    props.makeGetRequest(`claim_submittal/detail_claim/${pk}/`, 'ViewClaim');
  },[approval_value,paid_value, rejected_value]);

  useEffect(() => {
    props.makeGetRequest(`claim_submittal/detail_claim/${pk}/`, 'ViewClaim');
  },[]);

  useEffect(() => {

    setApproval(props.view_claim_data.approved)
    setPayment(props.view_claim_data.paid)
    setRejected(props.view_claim_data.rejected)

    setSuccessOpen(props.api_success)
    setErrorOpen(props.api_error)
    
    setApprovalDisabled(true)
    setPaymentDisabled(true)
    setRejectedDisabled(true)

    if (props.view_claim_data.Manager && !props.view_claim_data.approved && !props.view_claim_data.rejected) {
      setApprovalDisabled(false)
    }
    if (props.view_claim_data.Accountant && !props.view_claim_data.paid && 
      !props.view_claim_data.rejected && props.view_claim_data.approved) {
      setPaymentDisabled(false)
    }
    if (props.view_claim_data.Manager && !props.view_claim_data.rejected & !props.view_claim_data.approved) {
      setRejectedDisabled(false)
    }
  },[props]);

  const handleApproval = () => {
    const body = {'approved':true}
    props.makePatchRequest(body, pk, 'claim_submittal/approved_claim')
    setApproval(true)
    setApprovedOpen(false);
  }

  const handleRejected = () => {
    const body = {'rejected':true}
    props.makePatchRequest(body, pk, 'claim_submittal/rejected_claim')
    setRejected(true)
    setRejectedOpen(false);
  }

  const [reference, setReference] = useState(null);

  const handlePayment = (event) => {
    event.preventDefault();
    const body = {'paid':true, 'reference_no': reference}
    props.makePatchRequest(body, pk, 'claim_submittal/paid_claim')
    setPayment(true)
    handlePaidClose()
  }

  // const handleSuccessClose = (event, reason) => {
  //   if (reason === 'clickaway') {
  //     return;
  //   }
  //   setSuccessOpen(false);
  //   props.setAPISuccess()
  // };

  // const handleErrorClose = (event, reason) => {
  //   if (reason === 'clickaway') {
  //     return;
  //   }
  //   setErrorOpen(false);
  //   props.setAPIError()
  // };

  const handlePaidClickOpen = () => {
    setPaidOpen(true);
  };

  const handlePaidClose = () => {
    setPaidOpen(false);
  };

  const handleRejectedClickOpen = () => {
    setRejectedOpen(true);
  };

  const handleRejectedClose = () => {
    setRejectedOpen(false);
  };

  const handleApprovedClickOpen = () => {
    setApprovedOpen(true);
  };

  const handleApprovedClose = () => {
    setApprovedOpen(false);
  };


  if (props.view_claim_data.length !== 0){
  
  return (  
    <Container component="main" maxWidth="xs" sx={{ mb:5 }}>
    
      <Grid container>
        <Grid item xs={12} align="center">
          <Typography gutterBottom variant="h4" component="h4" value>
            Tjekaanvraag Inligting
          </Typography>
        </Grid>
        <Grid item xs={12} align="center" style={{marginBottom: 10}}>
          <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="claim_number"
                label="Verwysings Nommer"
                value={props.view_claim_data.client_ui + 
                  '-' + props.view_claim_data.date_month + '-' 
                  + props.view_claim_data.date_year + '-'
                  + props.view_claim_data.claim_count}
                InputProps={{
                  readOnly: true,
                }}           
              />
        </Grid>
        <Grid item xs={12} align="center" style={{marginBottom: 10}}>
          <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="submitter"
                label="Indiener"
                value={props.view_claim_data.user}
                InputProps={{
                  readOnly: true,
                }}           
              />
        </Grid>
        <Grid item xs={12} align="center" style={{marginBottom: 10}}>
          <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="submitter_email"
                label="Indiener e-pos adres"
                value={props.view_claim_data.email}  
                InputProps={{
                  readOnly: true,
                }}           
              />
        </Grid>
        <Grid item xs={12} align="center" style={{marginBottom: 10}}>
          <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="ministry"
                label="Aksie/Kommissie"
                value={props.view_claim_data.ministry}
                InputProps={{
                  readOnly: true,
                }}           
              />
        </Grid>
        <Grid item xs={12} align="center" style={{marginBottom: 10}}>
          <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="ministry"
                label="Aksie/Kommissie onderafdeling"
                value={props.view_claim_data.ministry_subdivision}
                InputProps={{
                  readOnly: true,
                }}           
              />
        </Grid>
        <Grid item xs={12} align="center" style={{marginBottom: 10}}>
          <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="expense"
                label="Uitgawe (Rand)"
                value={props.view_claim_data.expense}
                InputProps={{
                  readOnly: true,
                }}           
              />
        </Grid>
        {props.view_claim_data.Manager || props.view_claim_data.Accountant ?
        <>
        <Grid item xs={12} align="center" style={{marginBottom: 10}}>
          <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="budget"
                label="Begroting (Rand)"
                value={props.view_claim_data.ministry_subdivision_budget}
                InputProps={{
                  readOnly: true,
                }}           
              />
        </Grid>
        <Grid item xs={12} align="center" style={{marginBottom: 10}}>
        <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              id="total-spending"
              label="Totaal Spandeer (Rand)"
              value={props.view_claim_data.ministry_subdivision_total_spending}
              InputProps={{
                readOnly: true,
              }}           
            />
        </Grid>
        </>
        : <></>}
        <Grid item xs={12} align="center" style={{marginBottom: 10}}>
          <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="expense_motivation"
                label="Motivering vir Uitgawe"
                value={props.view_claim_data.expense_motivation} 
                InputProps={{
                  readOnly: true,
                }}           
              />
        </Grid>
        <Grid item xs={12} align="center" style={{marginBottom: 10}}>
          <a 
          href={props.view_claim_data.supporting_docs} 
          target="_blank" 
          style={{textDecorationLine: 'none'}}
          >
            <Button  
              color="warning"       
              variant="outlined"
              fullWidth
            >
              Kliek hier om bewys van uitgawe af te laai
            </Button>
            </a> 
        </Grid>
        <Grid item xs={12} align="center">
          <Typography gutterBottom variant="h6" component="h6">
            Begunstigde besonderhede
          </Typography>
        </Grid>
        <Grid item xs={12} align="center" style={{marginBottom: 10}}>
          <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="beneficiary"
                label="Begunstigde"
                value={props.view_claim_data.beneficiary}  
                InputProps={{
                  readOnly: true,
                }}           
              />
        </Grid>
        <Grid item xs={12} align="center" style={{marginBottom: 10}}>
          <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="beneficiary_email"
                label="Begunstigde e-pos adres"
                value={props.view_claim_data.beneficiary_email}  
                InputProps={{
                  readOnly: true,
                }}           
              />
        </Grid>
        <Grid item xs={12} align="center" style={{marginBottom: 10}}>
          <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="bank_name"
                label="Bank Naam"
                value={props.view_claim_data.bank_name}  
                InputProps={{
                  readOnly: true,
                }}           
              />
        </Grid>
        <Grid item xs={12} align="center" style={{marginBottom: 10}}>
          <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="account_no"
                label="Rekeningnommer"
                value={props.view_claim_data.account_no}  
                InputProps={{
                  readOnly: true,
                }}           
              />
        </Grid>
        <Grid item xs={12} align="center" style={{marginBottom: 10}}>
          <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="branch_code"
                label="Takkode/Naam"
                value={props.view_claim_data.branch_code}  
                InputProps={{
                  readOnly: true,
                }}           
              />
        </Grid>
        
        {props.view_claim_data.paid ? 
        <Grid item xs={12} align="center" style={{marginBottom: 10}}>
        <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              id="reference_no"
              label="Rekenmeester verwysingsnommer"
              value={props.view_claim_data.reference_no}  
              InputProps={{
                readOnly: true,
              }}           
            />
        </Grid>
        : <></>}
        

        {/* <Grid item xs={12} style={{marginBottom: 5}}>
          <Button  
              color="success"       
              variant="contained"
              disabled={approval_disabled}
              fullWidth
              onClick={handleApproval}
            >
            {props.view_claim_data.approved ? `Goedgekeur deur: ${props.view_claim_data.approved_by}`  : 'Bevestig Goedkeuring'}
          </Button>
        </Grid> */}

        <Grid item xs={12} style={{marginBottom: 5}}>
          <Button  
              color="success"       
              variant="contained"
              disabled={approval_disabled} //Done
              fullWidth
              onClick={handleApprovedClickOpen}
            >
            {props.view_claim_data.approved ? `Goedgekeur deur: ${props.view_claim_data.approved_by}`  : 'Bevestig Goedkeuring'}
          </Button>
          
          <Dialog open={approved_open} onClose={handleApprovedClose}>
            <DialogTitle>Bevestig Goedkeuring</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Is u seker dat u die tjekaanvraag wil goedkeur? Hierdie aksie kan nie teruggekeur word nie.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button 
                variant="contained"
                color="secondary" 
                onClick={handleApprovedClose}>
                  Kanselleer
                </Button>
              <Button 
                variant="contained"
                color="primary" 
                onClick={handleApproval}>
                  Bevestig Goedkeuring
                </Button>
            </DialogActions>
          </Dialog>
        </Grid>
        
        <Grid item xs={12} style={{marginBottom: 5}}>
          <Button  
              color="success"       
              variant="contained"
              disabled={payment_disabled}
              fullWidth
              onClick={handlePaidClickOpen}
            >
            {props.view_claim_data.paid ? `Betaling Goedgekeur deur: ${props.view_claim_data.paid_by}` : 'Bevestig Betaling is Uitgevoer'}
          </Button>
          
          <Dialog open={paid_open} onClose={handlePaidClose}>
            <Container container component="form" encType="multipart/form-data" onSubmit={handlePayment}>
            <DialogTitle>Verwysings Nommer</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Vul in die rekenmeester verwysings nommer verwant aan hierdie betaling
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="reference"
                label="Verwysings Nommer"
                fullWidth
                variant="standard"
                required
                onChange={event => setReference(event.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button 
                variant="contained"
                color="secondary" 
                onClick={handlePaidClose}>
                  Kanselleer
                </Button>
              <Button 
                variant="contained"
                color="primary" 
                type="submit">
                  Bevestig Goedkeuring
                </Button>
            </DialogActions>
            </Container>
          </Dialog>
        </Grid>

        <Grid item xs={12} style={{marginBottom: 5}}>
          <Button  
              color="error"       
              variant="contained"
              disabled={rejected_disabled}
              fullWidth
              onClick={handleRejectedClickOpen}
            >
            {props.view_claim_data.rejected ? `Afgekeur deur: ${props.view_claim_data.rejected_by}` : 'Bevestig afkeuring'}
          </Button>
          
          <Dialog open={rejected_open} onClose={handleRejectedClose}>
            <DialogTitle>Bevestig afkeuring</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Is u seker dat u die tjekaanvraag wil afkeur? Hierdie aksie kan nie teruggekeur word nie.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button 
                variant="contained"
                color="secondary" 
                onClick={handleRejectedClose}>
                  Kanselleer
                </Button>
              <Button 
                variant="contained"
                color="primary" 
                onClick={handleRejected}>
                  Bevestig afkeuring
                </Button>
            </DialogActions>
          </Dialog>
        </Grid>



        <Grid item xs={12} align="center" style={{marginBottom: 5}}>
          <Button  color="secondary"       
            variant="contained"
            onClick={() => history.goBack()}
          >
          Tuisblad
          </Button>
        </Grid>             
      </Grid>

      {/* <Snackbar open={success_open} autoHideDuration={6000} onClose={handleSuccessClose}>
        <Alert onClose={handleSuccessClose} severity="success" sx={{ width: '100%' }}>
         Suksesvol!
        </Alert>
      </Snackbar>
      <Snackbar open={error_open} autoHideDuration={6000} onClose={handleErrorClose}>
        <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
          Iets het fout gegaan!
        </Alert>
      </Snackbar> */}
    
    </Container>
  )} else {
    return;
  }
}

ViewClaim.propTypes = {

  makeGetRequest: PropTypes.func.isRequired,
  makePostRequest: PropTypes.func.isRequired,
  makePatchRequest: PropTypes.func.isRequired,
  setAPISuccess: PropTypes.func.isRequired,
  setAPIError: PropTypes.func.isRequired,
  view_claim_data: PropTypes.object.isRequired,
  api_error: PropTypes.bool.isRequired,
  api_success: PropTypes.bool.isRequired,

};

const mapStateToProps = (state, ownProps) => {
    return{ view_claim_data: state.handleAPI.view_claim_data, 
            api_error: state.handleAPI.api_error,
            api_success: state.handleAPI.api_success, }
  };

const mapDispatchToProps = dispatch => {
return {

  makeGetRequest: (url, componentName) => dispatch(makeGetRequest(url, componentName)),
  makePostRequest: (body, url, file) => dispatch(makePostRequest(body, url, file)),
  makePatchRequest: (body, pk, url) => dispatch(makePatchRequest(body, pk, url)),
  setAPISuccess: () => dispatch(setAPISuccess()),
  setAPIError: () => dispatch(setAPIError()),

}}

export default connect(mapStateToProps, mapDispatchToProps)(ViewClaim);