//React
import * as React from 'react';
import {useEffect} from 'react'
//Material UI
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
//Redux
import { logIn, setLoginFailed } from "../state/actions/"
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
//Routing
import {useHistory} from 'react-router-dom'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit">
        A & R Technologies
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

function LogIn(props) {

  // const token = props.token
  // const [checked, setChecked] = React.useState(true);
  // const handleChange = (event) => {
  //   setChecked(event.target.checked);
  // };

  let history = useHistory()
  
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

      let email = data.get('email');
      let password = data.get('password');

      props.logIn({email,password})
  };

  useEffect(() => {
    if(props.loggedIn) {
      history.push('/create_claim')
      props.setLoginFailed()
    }
  },[props.loggedIn]);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Teken in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="e-pos adres"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Wagwoord"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Collapse in={props.login_failed}>
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      props.setLoginFailed();
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
                severity="error"
              >
                Username or Password is incorrect!
              </Alert>
            </Collapse>
            {/* <FormControlLabel
              control={
              <Checkbox
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
                value="remember" 
                color="primary" 
              />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Teken in
            </Button>
            {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
            </Grid> */}
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}

LogIn.propTypes = {
    logIn: PropTypes.func.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    token: PropTypes.string,
    setLoginFailed: PropTypes.func.isRequired,
    login_failed: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return{token: state.handleAPI.token,
          loggedIn: state.auth.loggedIn,
          login_failed: state.auth.login_failed}
};

const mapDispatchToProps = dispatch => {
return {
  
  logIn: (body) => dispatch(logIn(body)),
  setLoginFailed: () => dispatch(setLoginFailed()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LogIn);