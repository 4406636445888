//React
import * as React from 'react';
import {useEffect} from 'react'
// Redux
import { makeGetRequest, setNewMinistryModal } from "../state/actions/"
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Material UI
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography';
// Router
//Components
import TableMinistriesList from './Tables/TableMinistriesList';
import NewMinistryModal from './Modals/NewMinistryModal';

function Ministries(props) {

  useEffect(() => {
    props.makeGetRequest('claim_submittal/ministries/', 'MinistryListData');
  },[]);
  
  return (
    <>
    <Box sx={{ width: '100%' }}>
      <Divider sx={{mb:2}}>
        {/* <Chip sx={{ fontSize: 'h6.fontSize'}} label="Aksies/Kommissies" /> */}
        <Button 
          sx={{mb:1}}
          fullWidth
          variant="outlined" 
          color="primary" 
          size="large"
          >            
            <Typography variant="h6" align="center">
            Aksies/Kommissies
            </Typography>
          </Button>
      </Divider>

      {props.ministry_list_data.length !== 0 && 
      <Grid container rowSpacing={1}>
        <Grid item xs={0} lg={2}/>
        <Grid item container xs={12} lg={8}>
          <TableMinistriesList ministry_list_data={props.ministry_list_data}/>
        </Grid>
        <Grid item xs={0} lg={2}/>
      </Grid>
      }

      <Grid container rowSpacing={1}>
        <Grid item xs={0} lg={2.65}/>
        <Grid item xs={12} lg={6.7} align="centre">
          <Button 
          sx={{mt:2, mb:5}}
          fullWidth
          variant="contained" 
          color="success" 
          size="medium"
          onClick={()=>props.setNewMinistryModal(true)}
          >
            Nuwe Aksie/Kommissie
          </Button>
        </Grid>
        <Grid item xs={0} lg={2.65}/>
      </Grid>
    </Box>

    <NewMinistryModal/>
    </>
  )
}

Ministries.propTypes = {

  makeGetRequest: PropTypes.func.isRequired,
  ministry_list_data: PropTypes.array,
};

const mapStateToProps = (state, ownProps) => {
    return{
      ministry_list_data: state.handleAPI.ministry_list_data,
    }
  };

const mapDispatchToProps = dispatch => {
return {
  makeGetRequest: (url, componentName) => 
    dispatch(makeGetRequest(url, componentName)),
  setNewMinistryModal: (open) => 
    dispatch(setNewMinistryModal(open)),
}}

export default connect(mapStateToProps, mapDispatchToProps)(Ministries);