//React
import * as React from 'react';
import {useEffect} from 'react'
//Material UI
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Alert from '@mui/material/Alert';
//Redux
import { makeGetRequest, setAPISuccess, setAPIError} from "../state/actions/"
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
//Components
import TableClaimPaidList from './Tables/TableClaimPaidList';
import TableClaimSubmittedList from './Tables/TableClaimSubmittedList';
import TableClaimApprovedList from './Tables/TableClaimApprovedList';
import TableClaimRejectedList from './Tables/TableClaimRejectedList';

// const Item = styled(Paper)(({ theme }) => ({
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
// }));

function Home(props) {

  props.setAPISuccess()
  props.setAPIError()

  useEffect(() => {
  props.makeGetRequest('claim_submittal/list_claims_submitted/', 'HomeSubmitted');
  props.makeGetRequest('claim_submittal/list_claims_approved/', 'HomeApproved');
  props.makeGetRequest('claim_submittal/list_claims_paid/', 'HomePaid');
  props.makeGetRequest('claim_submittal/list_claims_rejected/', 'HomeRejected');
  },[]);  

  return (
    <Box sx={{ width: '100%' }}>
      <Divider sx={{mb:2}}>
        <Chip sx={{ fontSize: 'h6.fontSize'}} label="Nuwe Tjekaanvrae" />
      </Divider>

      {props.home_submitted_data.length !== 0 ? 
      <Grid container rowSpacing={1}>
        <TableClaimSubmittedList home_submitted_data={props.home_submitted_data}/>
      </Grid>
      : 
      <Grid container>
        <Grid item xs={0} lg={1}/>
        <Grid item xs={12} lg={10}>
          <Alert severity="info">Daar is geen nuwe Tjekaanvrae nie</Alert>
        </Grid>
        <Grid item xs={0} lg={1}/>
      </Grid>
      }

      <Divider sx={{mt:2, mb:2}}>
        <Chip sx={{ fontSize: 'h6.fontSize'}} label="Goedgekeurde Tjekaanvrae" />
      </Divider>

      {props.home_approved_data.length !== 0 ? 
      <Grid container rowSpacing={1}>
        <TableClaimApprovedList home_approved_data={props.home_approved_data}/>
      </Grid>
      : 
      <Grid container>
        <Grid item xs={0} lg={1}/>
        <Grid item xs={12} lg={10}>
          <Alert severity="info">Daar is geen nuwe goedgekeurde Tjekaanvrae nie</Alert>
        </Grid>
        <Grid item xs={0} lg={1}/>
      </Grid>}

      <Divider sx={{mt:2, mb:2}}>
        <Chip sx={{ fontSize: 'h6.fontSize'}} label="Betaalde Tjekaanvrae" />
      </Divider>
      
      {props.home_paid_data.length !== 0 ? 
      <Grid container rowSpacing={1}>
        <TableClaimPaidList home_paid_data={props.home_paid_data}/>
      </Grid>
      :
      <Grid container>
        <Grid item xs={0} lg={1}/>
        <Grid item xs={12} lg={10}>
          <Alert severity="info">Daar is geen betaalde Tjekaanvrae nie</Alert>
        </Grid>
        <Grid item xs={0} lg={1}/>
      </Grid>}

      <Divider sx={{mt:2, mb:2}}>
        <Chip sx={{ fontSize: 'h6.fontSize'}} label="Afgekeurde Tjekaanvrae" />
      </Divider>

      {props.home_rejected_data.length !== 0 ? 
      <Grid container rowSpacing={1}>
        <TableClaimRejectedList home_rejected_data={props.home_rejected_data}/>
      </Grid>
      :
      <Grid container>
        <Grid item xs={0} lg={1}/>
        <Grid item xs={12} lg={10}>
          <Alert severity="info">Daar is geen afgekeurde Tjekaanvrae nie</Alert>
        </Grid>
        <Grid item xs={0} lg={1}/>
      </Grid>}

      <Divider sx={{mt:2, mb:2}}/>

    </Box>
  );
}

Home.propTypes = {

  makeGetRequest: PropTypes.func.isRequired,
  setAPISuccess: PropTypes.func.isRequired,
  setAPIError: PropTypes.func.isRequired,
  home_submitted_data: PropTypes.array.isRequired,
  home_approved_data: PropTypes.array.isRequired,
  home_paid_data: PropTypes.array.isRequired,
  home_rejected_data: PropTypes.array.isRequired,

};

const mapStateToProps = (state, ownProps) => {
    return{ home_submitted_data: state.handleAPI.home_submitted_data,
            home_approved_data: state.handleAPI.home_approved_data,
            home_paid_data: state.handleAPI.home_paid_data,
            home_rejected_data: state.handleAPI.home_rejected_data}
  };

const mapDispatchToProps = dispatch => {
return {

  makeGetRequest: (url, componentName) => dispatch(makeGetRequest(url, componentName)),
  setAPISuccess: () => dispatch(setAPISuccess()),
  setAPIError: () => dispatch(setAPIError()),

}}

export default connect(mapStateToProps, mapDispatchToProps)(Home);;
