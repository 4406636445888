//React-Tables
import { useGlobalFilter, usePagination, useTable } from "react-table";
//Material UI
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import React from "react";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
//Routing
//Components
import ButtonTable from "./ButtonTable";
import ButtonTableModal from "./ButtonTableModal";
import theme from "../../theme";

export const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <span>
      <TextField
        id="outlined-basic"
        label="Search"
        variant="outlined"
        size="small"
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
      />
    </span>
  );
};

const Table = ({ columns, data }) => {
  let index = 0;

  const handleEntriesChange = (event) => {
    setPageSize(event.target.value);
  };

  const switchColumn = (cell) => {

      let claim_link = `/view_claim/${cell.row.original["id"]}/`;
      let ministry_link = `/ministries/${cell.row.original["id"]}/`
      let sub_ministry_link = `/ministries/subministry/${cell.row.original["id"]}/`
      let ministry_name = cell.row.original["name"]
      let sub_ministry_name = cell.row.original["name"]

      switch (cell.column.Header) {
      case "#":
        index += 1;
        return <p>{index}</p>;
      case "REKENMEESTER VERWYSINGS NO":
        return (
          <ButtonTable link={claim_link} cell={cell}/>
        );
      case "INDIENER":
        return (
          <ButtonTable link={claim_link} cell={cell}/>
        );
      case "AKSIE / KOMMISSIE":
        return (
          <ButtonTable link={ministry_link} cell={cell} ministry_name={ministry_name}/>
        );
      case "UITGAWE (RAND)":
        return cell.render("Cell")
      case "AKSIE/KOMMISSIE ONDERAFDELING":
        return (
          <ButtonTableModal
          index = {cell.row.id}
          display = {cell.row.original.name}
          header = {cell.column.Header}
          />
        );
      case "BEGROTING (RAND)":
        return (
          <ButtonTableModal
          index = {cell.row.id}
          display = {cell.row.original.budget}
          header = {cell.column.Header}
          />
        );
      case "TOTAAL SPANDEER (RAND)":
        return (
          <ButtonTableModal
          index = {cell.row.id}
          display = {cell.row.original.total_spending}
          header = {cell.column.Header}
          />
        );
      default:
        return cell.render("Cell");
    }
  };

  

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    pageOptions,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    state,
    setGlobalFilter,
  } = useTable({ columns, data, initialState: { pageSize: 10 } }, useGlobalFilter, usePagination);

  const { globalFilter } = state;

  return (
    <>
      <Grid item xs={0} lg={1}></Grid>

      <Grid item xs={6} lg={5} align="left">
        <Select
          labelId="entries-label"
          id="entries-label"
          value={pageSize}
          onChange={handleEntriesChange}
          size="small"
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
      </Grid>

      <Grid item xs={6} lg={5} align="right">
        {" "}
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      </Grid>

      <Grid item xs={0} lg={1}></Grid>

      <Grid item xs={0} lg={1}></Grid>
      <Grid item xs={12} lg={10} mt={1} mb={1}>
        <Paper elevation={2}>
          <table {...getTableProps()} style={theme.tableStyle.primaryTable_table}>
            <thead stlye={theme.tableStyle.tableHead}>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps({
                        style: {
                          fontSize: theme.tableStyle.primaryTable_th.fontSize,
                          height: theme.tableStyle.primaryTable_th.height,
                          textAlign: theme.tableStyle.primaryTable_th.textAlign,
                          minWidth: column.minWidth,
                          width: column.width,
                          fontFamily: theme.typography.fontFamily,
                          backgroundColor: theme.palette.primary.main,
                          color: theme.tableStyle.primaryTable_th.color,
                        },
                      })}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td style={theme.tableStyle.primaryTable_td} {...cell.getCellProps()}>
                          {switchColumn(cell)}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Paper>
      </Grid>
      <Grid item xs={0} lg={1}></Grid>

      <Grid item xs={0} lg={1}></Grid>
      <Grid item xs={6} lg={5} align="left">
        {data.length > pageSize ? (
          <ButtonGroup aria-label="button group">
            <Button variant="outlined" onClick={() => previousPage()} disabled={!canPreviousPage}>
              Previous
            </Button>
            <Button variant="contained">{pageIndex + 1}</Button>
            <Button variant="outlined" onClick={() => nextPage()} disabled={!canNextPage}>
              Next
            </Button>
          </ButtonGroup>
        ) : (
          <></>
        )}
      </Grid>

      <Grid item xs={6} lg={5}></Grid>
      <Grid item xs={0} lg={1}></Grid>

    </>
  );
};
export default Table;

