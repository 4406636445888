//React
import * as React from 'react';
import {useState} from 'react';
import {useEffect} from 'react'
// Redux
import { makeGetRequest, setNewSubMinistryModal, setChangeMinistryNameModal } from "../state/actions"
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Material UI
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
// Router
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";
//Components
import TableSubMinistriesList from './Tables/TableSubMinistriesList';
import NewSubMinistryModal from './Modals/NewSubMinistryModal';
import ChangeMinistryNameModal from './Modals/ChangeMinistryNameModal';
import ChangeSubminNameModal from './Modals/ChangeSubminNameModal';
import ChangeSubminBudgetModal from './Modals/ChangeSubminBudgetModal';
import ChangeSubminSpendingModal from './Modals/ChangeSubminSpendingModal';
import theme from '../theme';

function SubMinistries(props) {

  let { pk } = useParams();

  useEffect(() => { 
    props.makeGetRequest(`claim_submittal/ministries/subdivisions/${pk}/`, 'MinistrySubDivisionListData')
  },[]);

  if(Object.keys(props.ministry_subdivision_list_data).length !== 0){
    return (
      <>
      <Box sx={{ width: '100%' }}>
  
        <Divider sx={{mb:1}}>
          <Button 
          sx={{mb:1}}
          fullWidth
          variant="outlined" 
          color="primary" 
          size="large"
          onClick={()=> props.setChangeMinistryNameModal(true)}
          >            
            <Typography variant="h6" align="center">
              {props.ministry_subdivision_list_data.ministry.name}
            </Typography>
          </Button>
        </Divider>
    
        <Grid container sx={{mt: 1}}>

          <Grid xs={0} lg={2} item container/>
          <Grid xs={12} lg={8} item container>

            <Grid xs={12} lg={12} item container align="centre" sx={{mt: 1}}>
              <TableSubMinistriesList ministry_subdivision_list_data={
                props.ministry_subdivision_list_data.ministry_subdivisions ?
                props.ministry_subdivision_list_data.ministry_subdivisions :
                []}/>
            </Grid>
            
            {props.ministry_subdivision_list_data.ministry_subdivisions.length === 0 && (
              <>
              <Grid item xs={0} lg={1}/>
              <Grid item xs={12} lg={10} align="left">
                <Alert severity="info"> Daar bestaan nog geen onderafdelings vir 
                {" " + props.ministry_subdivision_list_data.ministry.name} nie.
                Kliek op "Nuwe Onderafdeling" hieronder om een bytevoeg.
                </Alert>
              </Grid>
              <Grid item xs={0} lg={1}/>
              </>
            )}

            <Grid item xs={0} lg={1}/>
            <Grid item xs={12} lg={10} align="centre" sx={{mt:2}}>
              <Button 
              sx={{ mb:1}}
              fullWidth
              variant="contained" 
              color="success" 
              size="medium"
              onClick={()=> props.setNewSubMinistryModal(true)}
              >
                Nuwe Onderafdeling
              </Button>
            </Grid>
            <Grid item xs={0} lg={1}/>

            <Grid item xs={0} lg={1}/>
            <Grid item xs={12} lg={10} align="centre">
              <Link
                style={theme.palette.button_page_link.main}
                to={"/ministries"}
              >
                <Button 
                fullWidth
                variant="contained" 
                color="secondary" 
                size="medium"
                >
                  Terug na vorige bladsy
                </Button>
              </Link>                
            </Grid>
            <Grid item xs={0} lg={1}/>
  

  
          </Grid>
          <Grid xs={0} lg={2} item container/>
  
        </Grid>
  
      </Box>
  
      <NewSubMinistryModal/>
      <ChangeMinistryNameModal/>
      <ChangeSubminNameModal/>
      <ChangeSubminBudgetModal/>
      <ChangeSubminSpendingModal/>
      </>
    )
  } else {
    return (
      <></>
    )
  }  
  
}

SubMinistries.propTypes = {

  makeGetRequest: PropTypes.func,
  ministry_list_data: PropTypes.array,
  ministry_subdivision_list_data: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => {
    return{
      ministry_list_data: state.handleAPI.ministry_list_data,
      ministry_subdivision_list_data: state.handleAPI.ministry_subdivision_list_data,
    }
  };

const mapDispatchToProps = dispatch => {
return {
  makeGetRequest: (url, componentName) => 
    dispatch(makeGetRequest(url, componentName)),
  setNewSubMinistryModal: (open) => 
    dispatch(setNewSubMinistryModal(open)),
  setChangeMinistryNameModal: (open) => 
    dispatch(setChangeMinistryNameModal(open)),
}}

export default connect(mapStateToProps, mapDispatchToProps)(SubMinistries);;