//React
import React from 'react'
//Routing
import { Switch, Route } from 'react-router-dom';
//Components
import PrivateRoute from './PrivateRoute';
import ManagerRoute from './ManagerRoute';
import Home from '../Home'
import LogIn from '../Login'
import CreateClaim from '../CreateClaim';
import ViewClaim from '../ViewClaim';
import Ministries from '../Ministries';
import SubMinistries from '../SubMinistries';
import Notifications from '../Notifications';
import Maintenance from '../Maintenance';

const MainRouter = () => (
  <>
  <Notifications/>
  <Switch>
    <PrivateRoute exact path='/' component={Home}/>
    <Route exact path='/login' component={LogIn}/>
    <PrivateRoute exact path='/create_claim' component={CreateClaim}/>
    <PrivateRoute exact path='/view_claim/:pk' component={ViewClaim}/>
    <ManagerRoute exact path='/ministries' component={Ministries}/>
    <ManagerRoute exact path='/ministries/:pk' component={SubMinistries}/>
  </Switch>
  </>
  
);

  export default MainRouter;



