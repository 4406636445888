import React from "react";
import Table from "./Table";

function TableSubMinistriesList(props) {
  const columns = React.useMemo(
    () => [
      {
        Header: "AKSIE/KOMMISSIE ONDERAFDELING",
        accessor: "name",
        width: 500,
        minWidth: 50,
      },
      {
        Header: "BEGROTING (RAND)",
        accessor: "budget",
        width: 500,
        minWidth: 50,
      },
      {
        Header: "TOTAAL SPANDEER (RAND)",
        accessor: "total_spending",
        width: 500,
        minWidth: 50,
      },
    ],
    []
  );

  const data = React.useMemo(() => props.ministry_subdivision_list_data, [props]);

  return (
    <>
      <Table columns={columns} data={data} />
    </>
  );
}

export default TableSubMinistriesList;
