import React from "react";
import Table from "./Table";

function TableClaimPaidList(props) {
  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",
        width: 200,
        minWidth: 20,
      },
      {
        Header: "DATUM",
        accessor: "date",
        width: 1000,
        minWidth: 50,
      },
      {
        Header: "REKENMEESTER VERWYSINGS NO",
        accessor: "reference_no",
        width: 1000,
        minWidth: 50,
      },
      {
        Header: "AKSIE/KOMMISSIE",
        accessor: "ministry",
        width: 1000,
        minWidth: 50,
      },
      {
        Header: "UITGAWE (RAND)",
        accessor: "expense",
        width: 1000,
        minWidth: 50,
      },
    ],
    []
  );

  const data = React.useMemo(() => props.home_paid_data, [props]);

  return (
    <>
      <Table columns={columns} data={data} />
    </>
  );
}

export default TableClaimPaidList;
