import { createStore, applyMiddleware, compose } from "redux";
import thunk from 'redux-thunk';
import reducers from "./reducers/index";

const initialState = {};
const middleware = [thunk];

export const store = createStore(
    reducers,
    initialState,
    compose(
        applyMiddleware(...middleware),
        //Comment out the following line when deploying for production
        // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
      )
);

