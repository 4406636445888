import { combineReducers } from "redux";
import handleAPIReducer from "./handleAPIReducer";
import authReducer from "./authReducer";
import varsReducer from "./varsReducer";
import modalReducer from "./modalReducer"

const reducers = combineReducers({
    handleAPI: handleAPIReducer,
    auth: authReducer,
    vars: varsReducer,
    modal: modalReducer,
})

export default reducers