//React
import React from "react";
//Material UI
import Button from "@mui/material/Button";
//Routing
import { Link } from "react-router-dom";
//Components
import theme from "../../theme";

const ButtonTable = (props) => {

  return (
        <Link
          style={theme.palette.button_page_link.main}
          to={{pathname: props.link}}
        >
          <Button 
          variant="outlined" 
          color="primary" 
          size="small"
          >
            {props.cell.render("Cell")}
          </Button>
        </Link>
  );
};

export default ButtonTable;
