import { createTheme} from "@mui/material/styles";
import {
  blue,
} from "@mui/material/colors";

const theme = createTheme({

  palette: {
    
    // primary: {
    //   main: blue[500],
    //   contrastText: "#fff",
    // },

    // information: {
    //   main: blue[500],
    // },

    // secondary: {
    //   main: red[500],
    // },

    // error: {
    //   main: red[500],
    // },

    // warning: {
    //   main: orange[500],
    //   contrastText: "#fff",
    // },

    // success: {
    //   main: green[500],
    //   contrastText: "#fff",
    // },

    button_page_link: {
      main: { textDecorationLine: "none", color: "inherit" },
    },
  },

  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },

  tableStyle: {

    primaryTable_th: {
      fontSize: 13,
      height: 40,
      textAlign: "center",
      color: "#ffff",
      // width: 1000,
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      backgroundColor: blue[500],
    },

    primaryTable_td: {
      fontSize: 13,
      height: 40,
      textAlign: "center",
    },

    primaryTable_table: {
      border: "none",
      borderRadius: "4px",
      borderCollapse: "collapse",
      overflow: "hidden",
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },

  },

});

export default theme;
