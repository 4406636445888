import * as React from 'react';
import {useState} from 'react';
import {useEffect} from 'react'
// Redux imports
import { makeGetRequest, makePostRequest, 
          setAPISuccess, setAPIError, setLoading} from "../state/actions/"
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Material UI components
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { Container, Grid, Typography, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// Router
// import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function CreateClaim(props) {

  useEffect(() => {
    props.makeGetRequest('claim_submittal/ministries/', 'MinistryListData');
  },[]);

  // const [user, setUser] = useState(null);
  const [expense_motivation, setExpenseMotivation] = useState(null);
  const [beneficiary, setBeneficiary] = useState(null);
  const [beneficiary_email, setBeneficiaryEmail] = useState(null);
  const [bank_name, setBankName] = useState(null);
  const [account_no, setAccountNo] = useState(null);
  const [branch_code, setBranchCode] = useState(null);
  const [ministry, setMinistry] = useState('');
  const [ministry_subdivision, setMinistrySubDivision] = useState('');
  const [supporting_docs, setSupportingDocs] = useState(null);
  const [expense, setExpense] = useState(null); 
  // const [budget, setBudget] = useState(null);
  const [subdivision_display,setSubDivisionDisplay] = useState(false);
  const [file_error, setFileError] = useState(false);

  const handleMinistryChange = (event) => {
    setMinistry(event.target.value)    
  };

  useEffect(() => {
    if(ministry !== ''){
      props.makeGetRequest(`claim_submittal/ministries/subdivisions/${ministry}/`, 'MinistrySubDivisionListData')
    setTimeout(function(){ setSubDivisionDisplay(true) }, 1000);
    }    
  },[ministry]);

  const handleMinistrySubDivisionChange = (event) => {
    setMinistrySubDivision(event.target.value);
  };

  const handleFileUpload = (event) => {
    if(event.target.files[0].size > 20971520) {
      setFileError(true)
      setSupportingDocs(null)
      event.target.value = null;
    } 
    else {
      setSupportingDocs(event.target.files)
    }
  };

  const handleSetExpense = (event) => {
    if(event.target.value < 0) {
      event.target.value = 0
      setExpense(event.target.value)
    } else {
      setExpense(event.target.value)
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    props.setLoading()
    const body = new FormData(event.currentTarget)
    body.append('ministry_subdivision',ministry_subdivision)
    body.append('ministry',ministry)
    body.append('client_ui','NGWAV')
    props.makePostRequest(body, 'claim_submittal/create_claim/', true)
    setExpenseMotivation(null)
    setExpense(null)
    setSupportingDocs(null)
    setMinistry('')
    setMinistrySubDivision('')
    setBeneficiary(null)
    setBeneficiaryEmail(null)
    setBankName(null)
    setAccountNo(null)
    setBranchCode(null)
    setSupportingDocs(null)
  }

  // const handleSuccessClose = (event, reason) => {
  //   if (reason === 'clickaway') {
  //     return;
  //   }
  //   props.setAPISuccess()
  // };

  // const handleErrorClose = (event, reason) => {
  //   if (reason === 'clickaway') {
  //     return;
  //   }
  //   props.setAPIError()
  // };

  const handleFileErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setFileError(false)
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ mb:5 }}>
    <Grid container component="form" encType="multipart/form-data" onSubmit={handleSubmit} sx={{ '& button': { m: 2 }}} style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    }}>
      <Grid item xs={12} align="center">
        <Typography gutterBottom variant="h4" component="h4" value>
                Maak ñ nuwe Tjekaanvraag
        </Typography>
      </Grid>
      <Grid item xs={12} align="center" style={{marginBottom: 20, marginTop: 10}}>
        <FormControl fullWidth>
          <InputLabel id="ministry-label">Aksie/Kommissie</InputLabel>
          <Select
            labelId="ministry-label"
            id="ministry-select"
            value={ministry}
            label="Aksie/Kommissie"
            onChange={handleMinistryChange}
            required
          >
              {props.ministry_list_data.map( (item) =>(
                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
              ))}

          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} align="center" style={{marginBottom: 5}}>
        <FormControl fullWidth>
          <InputLabel id="ministry-subdivision-label">Aksie/Kommissie-Onderafdeling</InputLabel>
          <Select
            labelId="ministry-subdivision-label"
            id="ministry-subdivision-select"
            value={ministry_subdivision}
            label="Aksie/Kommissie-Onderafdeling"
            onChange={handleMinistrySubDivisionChange}
            required
          > 
            { subdivision_display ?
              props.ministry_subdivision_list_data.ministry_subdivisions.map( (item) =>(
                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
              )) : <MenuItem value={''}></MenuItem>}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} align="center" style={{marginBottom: 5}}>
        <TextField onChange={handleSetExpense}
          margin="normal"
          required
          fullWidth
          id="expense"
          label="Uitgawe"
          name="expense"
          autoComplete="expense"
          error= {!(/^(([0-9]{1,10})(\.[0-9]{1,2})?)$/.test(expense))}
          helperText={!(/^(([0-9]{1,10})(\.[0-9]{1,2})?)$/.test(expense)) 
          ? "Die bedrag mag slegs 10 numeriese karakters of minder wees, 2 desimale plekke is ook aanvaarbaar." 
          : "" }
          inputProps={{ inputMode: 'numeric', type: "number" }}
          value={expense || ""}          
        />
      </Grid>    
      <Grid item xs={12} align="center" style={{marginBottom: 5}}>
        <TextField 
          onChange={event => setExpenseMotivation(event.target.value)}
          margin="normal"
          required
          fullWidth
          id="expense_motivation"
          label="Motivering vir Uitgawe"
          name="expense_motivation"
          autoComplete="expense_motivation"
          value={expense_motivation || ""}         
        />
      </Grid>
      <Grid item xs={12} align="center" style={{marginBottom: 5}}>
        <TextField onChange={handleFileUpload}
              InputLabelProps={{ shrink: true }}
              margin="normal"
              required
              fullWidth
              id="supporting_docs"
              label="Bewys van uitgawe"
              name="supporting_docs"
              type="file"
            />
      </Grid>

      <Grid item xs={12} align="center">
        <Typography gutterBottom variant="h6" component="h6">
          Begunstigde besonderhede
        </Typography>
      </Grid>

      <Grid item xs={12} align="center" style={{marginBottom: 5}}>
        <TextField onChange={event => setBeneficiary(event.target.value)}
              margin="normal"
              required
              fullWidth
              id="beneficiary"
              label="Naam"
              name="beneficiary"
              autoComplete="beneficiary"
              inputProps={{ maxLength: 50 }}  
              value={beneficiary || ""}       
            />
      </Grid>
      <Grid item xs={12} align="center" style={{marginBottom: 5}}>
        <TextField onChange={event => setBeneficiaryEmail(event.target.value)}
              margin="normal"
              required
              fullWidth
              id="beneficiary_email"
              label="E-pos adres"
              name="beneficiary_email"
              autoComplete="beneficiary_email"
              inputProps={{ maxLength: 50 }}  
              value={beneficiary_email || ""}       
            />
      </Grid>  

      <Grid item xs={12} align="center" style={{marginBottom: 5}}>
        <TextField onChange={event => setBankName(event.target.value)}
              margin="normal"
              required
              fullWidth
              id="bank_name"
              label="Bank Naam"
              name="bank_name"
              autoComplete="bank_name"
              inputProps={{ maxLength: 20 }}  
              value={bank_name || ""}       
            />
      </Grid>   
      <Grid item xs={12} align="center" style={{marginBottom: 5}}>
        <TextField onChange={event => setAccountNo(event.target.value)}
              margin="normal"
              required
              fullWidth
              id="account_no"
              label="Rekeningnommer"
              name="account_no"
              autoComplete="account_no"
              inputProps={{ maxLength: 20 }}
              value={account_no || ""}                     
            />
      </Grid>
      <Grid item xs={12} align="center" style={{marginBottom: 5}}>
        <TextField onChange={event => setBranchCode(event.target.value)}
              margin="normal"
              required
              fullWidth
              id="branch_code"
              label="Takkode/Naam"
              name="branch_code"
              autoComplete="branch_code"
              inputProps={{ maxLength: 20 }}
              value={branch_code || ""}                 
            />
      </Grid>      
      <Grid item xs={12} align="center" style={{marginBottom: 5}}>     
        <Link style={{textDecorationLine: 'none'}} to={`/`}>
          <Button      
              variant="contained"
              color="secondary" 
          >
              Tuisblad
          </Button>
        </Link>
          <Button 
            type="submit"          
            variant="contained"
            color="primary" 
            >
              Dien vorm in
          </Button>
      </Grid>             
    </Grid>

    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={props.loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    {/* <Snackbar open={props.api_success} autoHideDuration={6000} onClose={handleSuccessClose}>
      <Alert onClose={handleSuccessClose} severity="success" sx={{ width: '100%' }}>
        Die vorm indiening was suksesvol!
      </Alert>
    </Snackbar>
    <Snackbar open={props.api_error} autoHideDuration={6000} onClose={handleErrorClose}>
      <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
        Iets het fout gegaan, die vorm indiening was nie suksesvol nie!
      </Alert>
    </Snackbar> */}
    <Snackbar open={file_error} autoHideDuration={6000} onClose={handleFileErrorClose}>
      <Alert onClose={handleFileErrorClose} severity="error" sx={{ width: '100%' }}>
        Die dokument wat u wil oplaai is te groot! Dit moet kleiner as 20MB wees.
      </Alert>
    </Snackbar>

    </Container>

  )
}

CreateClaim.propTypes = {

  makeGetRequest: PropTypes.func.isRequired,
  makePostRequest: PropTypes.func.isRequired,
  setAPISuccess: PropTypes.func.isRequired,
  setAPIError: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  ministry_list_data: PropTypes.array,
  ministry_subdivision_list_data: PropTypes.object,
  api_error: PropTypes.bool.isRequired,
  api_success: PropTypes.bool.isRequired,
  api_error_message: PropTypes.string.isRequired,
  api_success_message: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  

};

const mapStateToProps = (state, ownProps) => {
    return{
      ministry_list_data: state.handleAPI.ministry_list_data,
      ministry_subdivision_list_data: state.handleAPI.ministry_subdivision_list_data, 
      api_error: state.handleAPI.api_error,
      api_success: state.handleAPI.api_success,
      api_error_message: state.handleAPI.api_error_message,
      api_success_message: state.handleAPI.api_success_message,
      loading: state.handleAPI.loading,}
  };

const mapDispatchToProps = dispatch => {
return {

  makeGetRequest: (url, componentName) => dispatch(makeGetRequest(url, componentName)),
  makePostRequest: (body, url, file) => dispatch(makePostRequest(body, url, file)),
  setAPISuccess: () => dispatch(setAPISuccess()),
  setAPIError: () => dispatch(setAPIError()),
  setLoading: () => dispatch(setLoading()),

}}

export default connect(mapStateToProps, mapDispatchToProps)(CreateClaim);;