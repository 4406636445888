import { 
    NEW_MINISTRY,
    NEW_SUBMINISTRY,
    CHANGE_MINISTRY_NAME,
    CHANGE_SUBMIN_NAME,
    CHANGE_SUBMIN_BUDGET,
    CHANGE_SUBMIN_SPENDING, 
} from './modalTypes';

export const setNewMinistryModal = (open) => {
    return (dispatch) => {
        dispatch({
            type: NEW_MINISTRY,
            open: open
        })
    }
}

export const setNewSubMinistryModal = (open) => {
    return (dispatch) => {
        dispatch({
            type: NEW_SUBMINISTRY,
            open: open
        })
    }
}

export const setChangeMinistryNameModal = (open) => {
    return (dispatch) => {
        dispatch({
            type: CHANGE_MINISTRY_NAME,
            open: open
        })
    }
}

export const setChangeSubminNameModal = (open, submin_index) => {
    return (dispatch) => {
        dispatch({ 
          type: CHANGE_SUBMIN_NAME,
          open: open,
          submin_index: submin_index
        });
    };
};

export const setChangeSubminBudgetModal = (open, submin_index) => {
    return (dispatch) => {
        dispatch({ 
          type: CHANGE_SUBMIN_BUDGET,
          open: open,
          submin_index: submin_index
        });
    };
};

export const setChangeSubminSpendingModal = (open, submin_index) => {
    return (dispatch) => {
        dispatch({ 
          type: CHANGE_SUBMIN_SPENDING,
          open: open,
          submin_index: submin_index
        });
    };
};